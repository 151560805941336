import { useMemo } from 'react';
import { RENOVATED_PROPERTY_CHIP } from '../constants';
import { useGetMVTSelections } from './use-get-mvt-selections';
import { useTranslation } from 'next-i18next';
import { useFeatureToggleConfig } from './use-feature-toggle';

export const useRenovationChipMVT = (hotelInBounds: string[]) => {
  const { t } = useTranslation('hotel-badges');
  const { enabled, config } = useFeatureToggleConfig('config-renovated-properties');
  const renovatedProperties: string[] = enabled ? config.renovatedProperties : [];

  const renoPropertyInResults = renovatedProperties.filter((renovatedCtyhocn) =>
    hotelInBounds.includes(renovatedCtyhocn)
  );

  const { isMVTVariant, isLoaded } = useGetMVTSelections({
    agentId: RENOVATED_PROPERTY_CHIP.agentId,
    isProvisional: !!renoPropertyInResults.length,
  });

  const renovatedCtyhocns = useMemo(() => {
    if (isLoaded && isMVTVariant.b && !!renoPropertyInResults.length) {
      const renoChipCopyMap = {};
      renoPropertyInResults.forEach(
        (ctyhocn) => (renoChipCopyMap[ctyhocn as keyof typeof renoChipCopyMap] = t('recentlyReno'))
      );
      return renoChipCopyMap;
    }

    return null;
  }, [isLoaded, isMVTVariant.b, renoPropertyInResults, t]);

  return renovatedCtyhocns;
};
