import type { IconNames } from '@dx-ui/osc-icon';
import Icon from '@dx-ui/osc-icon';
import cx from 'classnames';

export const InfoMessageBanner = ({
  bannerClassName,
  message,
  children,
  centerInfoIcon,
  iconClassName,
  iconName = 'info-circle',
}: {
  message?: string;
  children?: React.ReactNode;
  centerInfoIcon?: boolean;
  bannerClassName?: string;
  iconClassName?: string;
  iconName?: (typeof IconNames)[number];
}) => (
  <h3
    className={cx(
      'mb-4 flex px-4 py-3 font-semibold leading-none',
      {
        'bg-bg-primary': !bannerClassName,
      },
      bannerClassName
    )}
  >
    <div className={cx({ 'flex items-center': centerInfoIcon })}>
      <Icon name={iconName} variant="regular" size="sm" className={iconClassName} />
    </div>
    <div className="flex flex-col">
      <span className="pl-2 text-base">{message}</span>
      {children}
    </div>
  </h3>
);
