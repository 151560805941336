'use client';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { useTranslation } from 'react-i18next';
import { useGetMVTSelections } from '../../../dx-shop-search-ui/hooks';
import { BIGGER_MAP_CTA, GOALS } from '../../../dx-shop-search-ui/constants';
import cx from 'classnames';
import { useIsClient, useMediaQuery } from 'usehooks-ts';
import { sendReward } from '@dx-ui/framework-conductrics';

export const MapListToggleButton = ({
  showMapListToggleButton = true,
}: {
  showMapListToggleButton?: boolean;
}) => {
  const { isListVisible } = useAppState();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('search-page');
  const isClient = useIsClient();
  const isSlimView = useMediaQuery('(max-width: 767px)');

  //toggle off MW map view if viewport resized to desktop
  useEffect(() => {
    if (!isSlimView && !isListVisible) dispatch({ type: 'SET_IS_LIST_VISIBLE', payload: true });
  }, [dispatch, isListVisible, isSlimView]);

  const { isLoaded, isMVTVariant } = useGetMVTSelections({
    agentId: BIGGER_MAP_CTA.agentId,
    isProvisional: isSlimView,
    MVTVariants: ['a', 'b', 'c'],
  });
  const isBiggerCTAVariant = isLoaded && (isMVTVariant.b || isMVTVariant.c);

  const mapListToggleButtonText = useCallback(() => {
    if (isBiggerCTAVariant) return isListVisible ? t('viewMap') : t('viewList');
    return isListVisible ? t('showMap') : t('showList');
  }, [isBiggerCTAVariant, isListVisible, t]);

  return isClient && showMapListToggleButton ? (
    <button
      className={cx('btn btn-primary items-center md:hidden rtl:ml-0 rtl:mr-auto', {
        'w-full h-10': isBiggerCTAVariant,
      })}
      onClick={() => {
        sendReward(GOALS.MapCTAClick);
        dispatch({ type: 'SET_IS_LIST_VISIBLE', payload: !isListVisible });
      }}
      type="button"
    >
      {mapListToggleButtonText()}
    </button>
  ) : null;
};
