import { useTranslation } from 'next-i18next';
import { useGetCompareHotelMVTVariants } from './hooks/use-get-compare-hotels-mvt';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { sendReward } from '@dx-ui/framework-conductrics';
import { GOALS } from '../../constants';
import { useWrappedRouter } from '../../hooks';

type CompareToggleProps = {
  onChange?: () => void;
};

export const CompareHotelsToggle = ({ onChange }: CompareToggleProps) => {
  const { t } = useTranslation('compare-hotels');
  const { safeQueryParams: queryParameters } = useWrappedRouter();

  const { isCompareHotelsToggleVariant } = useGetCompareHotelMVTVariants();

  const { compareHotelsToggle, hotelsToCompare } = useAppState();
  const dispatch = useAppDispatch();

  const handleInputChange = () => {
    if (onChange) onChange();
    if (!compareHotelsToggle) sendReward(GOALS.compareHotelsToggleOn);
    //when hotels to compare still selected and  toggle is set to false - remove previous selections
    if (!compareHotelsToggle && hotelsToCompare.length)
      dispatch({ type: 'SET_HOTELS_TO_COMPARE', payload: [] });
    dispatch({ type: 'SET_COMPARE_HOTELS_TOGGLE', payload: !compareHotelsToggle });
  };

  return isCompareHotelsToggleVariant && !queryParameters?.datesFlex ? (
    <div className="ml-1 mr-2 pt-5 rtl:mb-2">
      <div className="flex">
        <label className="flex cursor-pointer font-bold">
          <div className="relative mr-1 inline-flex">
            <input className="peer sr-only" type="checkbox" onChange={handleInputChange} />
            <div className="peer-checked:bg-hilton peer-focus:ring-hilton peer-checked:after:border-bg after:border-bg-disabled after:bg-bg peer h-6 w-12 rounded-full bg-[#CFCFCF] ring-offset-1 after:absolute after:left-[4px] after:top-[2px] after:size-5 after:rounded-full after:border after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-focus:outline-none peer-focus:ring-2 motion-reduce:transition-none" />
          </div>
          <span className="text-sm first-letter:capitalize">{t('compareHotels')}</span>
        </label>
      </div>
    </div>
  ) : null;
};
