import { v4 } from 'uuid';

export const WhyPropertySkeleton = ({ variants }: { variants: Record<string, boolean> }) => {
  return (
    <div className="h-40 w-full">
      <div className="bg-bg-alt my-2 h-5 w-32 animate-pulse" />

      {variants?.b ? (
        <>
          <div className="bg-bg-alt my-2 h-5 w-full animate-pulse" />
          <div className="bg-bg-alt my-2 h-5 w-full animate-pulse" />
        </>
      ) : null}
      {variants?.c ? (
        <div className=" flex flex-wrap gap-2">
          <div className="bg-bg-alt my-2 h-5 w-36 animate-pulse" />
          <div className="bg-bg-alt my-2 h-5 w-36 animate-pulse" />
          <div className="bg-bg-alt my-2 h-5 w-36 animate-pulse" />
        </div>
      ) : null}
      <div className="bg-bg-alt my-2 h-5 w-32 animate-pulse" />
      <div className=" flex flex-wrap gap-2">
        {Array(6)
          .fill(null)
          .map((_) => (
            <div key={v4()} className="bg-bg-alt h-5 w-28 animate-pulse" />
          ))}
      </div>
    </div>
  );
};
