import { useTranslation } from 'react-i18next';
import { InfoMessageBanner } from '../list-view/info-message-banner/info-message-banner';
import { useMediaQuery } from 'usehooks-ts';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { useGetCompareHotelMVTVariants } from './hooks/use-get-compare-hotels-mvt';
import { sendReward } from '@dx-ui/framework-conductrics';
import { GOALS } from '../../constants';
import { useState } from 'react';
import { DESKTOP_COMPARE_LIMIT, MOBILE_COMPARE_LIMIT } from './compare-hotel-utils';
import { CompareModal } from './compare-modal';
import type { GoogleCoordinate } from '../../utils';

type CompareBarProps = {
  hotelsToCompare?: string[];
  centerCoordinate: GoogleCoordinate | null;
  onCancel?: () => void;
  onCompare?: () => void;
};

export const CompareBar = ({ onCancel, onCompare, centerCoordinate }: CompareBarProps) => {
  const { t } = useTranslation('compare-hotels');
  const isMobile = useMediaQuery('(max-width:640px)');
  const { isCompareHotelsCheckboxVariant, isCompareHotelsToggleVariant } =
    useGetCompareHotelMVTVariants();
  const [showCompareModal, setShowCompareModal] = useState<boolean>(false);
  const { hotelsToCompare, compareHotelsToggle } = useAppState();
  const dispatch = useAppDispatch();

  const showCompareBar =
    isCompareHotelsCheckboxVariant || (isCompareHotelsToggleVariant && compareHotelsToggle);

  const selectHotelsBannerMessage = () => {
    if (hotelsToCompare.length < 2) {
      if (hotelsToCompare.length === 1) return t('selectOneMoreHotel');
      return t('selectHotelsToStart');
    }
    return null;
  };

  const handleCompareHotels = () => {
    if (onCompare) onCompare();
    sendReward(GOALS.compareHotelsCTAClick);
    setShowCompareModal(true);
  };

  const closeCompareModal = () => setShowCompareModal(false);

  const handleStopComparing = () => {
    if (onCancel) onCancel();
    if (hotelsToCompare.length) dispatch({ type: 'SET_HOTELS_TO_COMPARE', payload: [] });
    closeCompareModal();
  };

  return showCompareBar && hotelsToCompare.length ? (
    <>
      <div className="bg-bg-alt border-border sticky bottom-0 z-10 flex w-full flex-col items-center justify-between gap-3 border-t-2 p-4 md:flex-row">
        {hotelsToCompare.length < 2 ? (
          <InfoMessageBanner bannerClassName="bg-bg-alt gap-3 flex items-center pb-0 font-base">
            <span aria-live="assertive"> {selectHotelsBannerMessage()} </span>
          </InfoMessageBanner>
        ) : (
          <div aria-live="assertive">
            {t('numOfHotelsCompared', {
              compareHotelsSelected: hotelsToCompare.length,
              compareLimit: isMobile ? `${MOBILE_COMPARE_LIMIT}` : `${DESKTOP_COMPARE_LIMIT}`,
            })}
          </div>
        )}
        <div className="flex w-full flex-col-reverse gap-4  text-sm md:w-auto md:flex-row">
          {hotelsToCompare.length ? (
            <button type="button" onClick={handleStopComparing} className="btn-primary-link">
              {t('stopComparing')}
            </button>
          ) : null}
          <button
            type="button"
            className="btn-primary disabled:bg-text-disabled w-full rounded p-3 font-bold md:w-72 lg:w-[343px]"
            disabled={hotelsToCompare.length < 2}
            onClick={handleCompareHotels}
          >
            {t('compareHotels')}
          </button>
        </div>
      </div>
      <CompareModal
        centerCoordinate={centerCoordinate}
        hotelsToCompare={[...hotelsToCompare]}
        isOpen={showCompareModal}
        onDismiss={closeCompareModal}
        onStopCompare={handleStopComparing}
      />
    </>
  ) : null;
};
