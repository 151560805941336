import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useGetCompareHotelMVTVariants } from './hooks/use-get-compare-hotels-mvt';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { sendReward } from '@dx-ui/framework-conductrics';
import { GOALS } from '../../constants';
import { useMediaQuery } from 'usehooks-ts';
import { DESKTOP_COMPARE_LIMIT, MOBILE_COMPARE_LIMIT } from './compare-hotel-utils';

type CompareCheckboxProps = {
  ctyhocn?: string;
  onChange?: () => void;
};

export const CompareHotelsCheckbox = ({
  onChange,
  ctyhocn: selectedCtyhocn,
}: CompareCheckboxProps) => {
  const { t } = useTranslation('compare-hotels');
  const isSubDesktop = useMediaQuery('(max-width: 1023px)');
  const { isCompareHotelsCheckboxVariant, isCompareHotelsToggleVariant } =
    useGetCompareHotelMVTVariants();

  const { hotelsToCompare, compareHotelsToggle } = useAppState();
  const dispatch = useAppDispatch();
  const showCompareCheckBox =
    isCompareHotelsCheckboxVariant || (isCompareHotelsToggleVariant && compareHotelsToggle);

  const handleCompareHotelChange = () => {
    if (onChange) onChange();

    if (selectedCtyhocn) {
      let selectedHotels = [...hotelsToCompare];
      if (hotelsToCompare.includes(selectedCtyhocn))
        selectedHotels = hotelsToCompare?.filter((hotel) => hotel !== selectedCtyhocn);
      else selectedHotels.push(selectedCtyhocn);
      dispatch({ type: 'SET_HOTELS_TO_COMPARE', payload: selectedHotels });
      if (selectedHotels.length) sendReward(GOALS.compareHotelsCheckboxSelected);
    }
  };
  const isCompareCBChecked = selectedCtyhocn ? !!hotelsToCompare?.includes(selectedCtyhocn) : false;
  //Disable checkbox when hotels selected is 2 for mobile and  5 selected for other large device
  const isCompareCBDisabled =
    !isCompareCBChecked &&
    (isSubDesktop
      ? hotelsToCompare?.length >= MOBILE_COMPARE_LIMIT
      : hotelsToCompare?.length === DESKTOP_COMPARE_LIMIT);

  return showCompareCheckBox ? (
    <div className="mr-0 rtl:mb-2">
      <div className="flex">
        <label className="label label-inline-reverse rtl:label-inline-reverse">
          <span className={cx('font-semibold rtl:pr-1 rtl:pl-2 text-base')}>{t('compare')}</span>
          <input
            className="form-checkbox"
            checked={isCompareCBChecked}
            disabled={isCompareCBDisabled}
            onChange={handleCompareHotelChange}
            type="checkbox"
          />
        </label>
      </div>
    </div>
  ) : null;
};
