import {
  NUM_AMENITIES_SUGGESTIONS_WANTED,
  HotelAttributeIds,
  MAX_SUGGESTED_AMENITY_FILTERS_WITH_SALE_FILTER,
} from '../components/filters/filter.constants';
import type { AmenityItem } from '../components/filters/suggested-filters';
import type { HotelAmenityId } from '../gql/types';
import { useAppState } from '../providers/app-provider';
import type { HotelType } from '../providers/app-provider/app-provider.types';
import { useMemo } from 'react';
import { useFeatureToggleConfig } from './use-feature-toggle';
import type { Maybe } from '@dx-ui/gql-types';
import { useTranslation } from 'next-i18next';
import { HIGHEST_AMENITIES_SUGGESTED_FILTERS } from '../constants';
import { useGetMVTSelections } from './use-get-mvt-selections';

const findAmenity = (wantedAmenityId: string, amenities: AmenityItem[]) => {
  const foundAmenity = amenities.find((amenity) => amenity.id === wantedAmenityId);
  return (
    foundAmenity || {
      id: wantedAmenityId,
      name: wantedAmenityId,
    }
  );
};

const calculateSuggestedFilterCounts = ({
  filtersList,
  visibleHotels,
  hotelsInBounds,
}: {
  filtersList?: string[];
  visibleHotels: string[];
  hotelsInBounds: Record<string, HotelType>;
}) => {
  return filtersList?.length
    ? filtersList.reduce<{
        isAtleastOneSuggestedFilter: boolean;
        suggestedFilterCounts: { [key: string]: number };
      }>(
        (result, suggestedFilter) => {
          const count = visibleHotels.reduce((count, hotel) => {
            const inBoundsHotel = hotelsInBounds[hotel];
            if (inBoundsHotel) {
              return inBoundsHotel.amenityIds.includes(suggestedFilter as HotelAmenityId)
                ? count + 1
                : count;
            }
            return count;
          }, 0);

          return {
            isAtleastOneSuggestedFilter: result.isAtleastOneSuggestedFilter || count > 0,
            suggestedFilterCounts: {
              ...result.suggestedFilterCounts,
              [suggestedFilter]: count,
            },
          };
        },
        {
          isAtleastOneSuggestedFilter: false,
          suggestedFilterCounts: {},
        }
      )
    : {
        isAtleastOneSuggestedFilter: false,
        suggestedFilterCounts: {},
      };
};

const calculateTopSuggestedFilters = (
  suggestedFilterCounts: { [key: string]: number },
  amenities: AmenityItem[],
  maxSuggestedFilters: number
) => {
  const topSuggestedFilters: { [key: string]: AmenityItem } = {};

  for (const suggestedFilter of Object.keys(suggestedFilterCounts)) {
    const count = suggestedFilterCounts[suggestedFilter] ?? 0;

    if (count > 0 && Object.keys(topSuggestedFilters)?.length < maxSuggestedFilters) {
      topSuggestedFilters[suggestedFilter] = findAmenity(suggestedFilter, amenities);
      const topFilter = topSuggestedFilters[suggestedFilter];
      if (topFilter) topFilter.count = suggestedFilterCounts[suggestedFilter];
    } else if (count > 0) {
      const smallestCountKey = Object.keys(topSuggestedFilters).reduce((a, b) => {
        const filterA = topSuggestedFilters[a];
        const filterB = topSuggestedFilters[b];
        if (filterA && filterB) {
          const aCount = filterA.count || 0;
          const bCount = filterB.count || 0;
          return aCount < bCount ? a : b;
        }
        return a;
      });

      const smallestCount = topSuggestedFilters[smallestCountKey]?.count;

      if (!smallestCount || count > smallestCount) {
        delete topSuggestedFilters[smallestCountKey];
        topSuggestedFilters[suggestedFilter] = findAmenity(suggestedFilter, amenities);
        const topSuggestedFilter = topSuggestedFilters[suggestedFilter];
        if (topSuggestedFilter) topSuggestedFilter.count = suggestedFilterCounts[suggestedFilter];
      }
    }
  }
  return topSuggestedFilters;
};

const getSuggestedFiltersQuant = ({ hasSalesFilter }: { hasSalesFilter: boolean }) => {
  if (hasSalesFilter) {
    return MAX_SUGGESTED_AMENITY_FILTERS_WITH_SALE_FILTER;
  } else {
    return NUM_AMENITIES_SUGGESTIONS_WANTED;
  }
};
//NHCSEARCH-5346
const HIGHEST_CONVERTING_AMENITIES = ['evCharging', 'freeParking', 'airportShuttle', 'petsAllowed'];
const CONGFIGURED_SUGGESTED_FILTERS = [
  'dx-location::locality::us::ny::new-york-city',
  'dx-location::locality::us::ca::los-angeles',
  'dx-location::locality::us::fl::orlando',
  'dx-location::locality::us::il::chicago',
  'dx-location::locality::us::dc::washington-dc',
  'dx-location::locality::us::nv::las-vegas',
  'dx-location::locality::gb::london',
  'dx-location::locality::us::ga::atlanta',
  'dx-location::locality::us::ca::orange',
  'dx-location::locality::us::ca::san-diego',
  'dx-location::locality::us::tx::dallas',
  'dx-location::locality::us::ma::boston',
  'dx-location::locality::us::hi::honolulu',
  'dx-location::locality::us::fl::ft-lauderdale',
  'dx-location::locality::us::fl::miami',
  'dx-location::locality::us::tn::nashville',
  'dx-location::locality::mx::cancun',
  'dx-location::locality::us::fl::tampa',
  'dx-location::locality::us::tx::houston',
  'dx-location::locality::us::az::phoenix',
];
export const useSuggestedFilters = ({
  visibleHotels,
  amenities,
  matchId,
  saleFilter,
}: {
  visibleHotels: string[];
  amenities: AmenityItem[];
  matchId?: Maybe<string>;
  saleFilter?: { showSaleFilter: boolean; saleFilterCount: number };
}) => {
  const { hotelsInBounds } = useAppState();
  const { t } = useTranslation('filters');

  const { isLoaded: isConducticsLoaded, isMVTVariant: isHighestConvertingAmenitiesVariant } =
    useGetMVTSelections({ agentId: HIGHEST_AMENITIES_SUGGESTED_FILTERS.agentId });

  const isHighestConvertingAmenitiesTest =
    isConducticsLoaded && isHighestConvertingAmenitiesVariant.b;

  const { config: defaultConfig, configFromRule } = useFeatureToggleConfig(
    'config-rule-search-ui-suggested-filters',
    { matchId: matchId || '' }
  );

  const filtersList =
    isHighestConvertingAmenitiesTest && matchId && !CONGFIGURED_SUGGESTED_FILTERS.includes(matchId)
      ? HIGHEST_CONVERTING_AMENITIES
      : (configFromRule || defaultConfig)?.suggestedFilters;
  const suggestedSaleFilter = useMemo(
    () =>
      saleFilter?.showSaleFilter && visibleHotels.length <= 150
        ? {
            sale: {
              id: HotelAttributeIds.sale,
              name: t('saleRates'),
              count: saleFilter?.saleFilterCount,
            },
          }
        : null,
    [saleFilter?.saleFilterCount, saleFilter?.showSaleFilter, t, visibleHotels.length]
  );

  const maxSuggestedFilters = getSuggestedFiltersQuant({
    hasSalesFilter: Boolean(suggestedSaleFilter),
  });

  // get the count of all filters in filtersList
  const { isAtleastOneSuggestedFilter, suggestedFilterCounts } = useMemo(
    () => calculateSuggestedFilterCounts({ filtersList, visibleHotels, hotelsInBounds }),
    [filtersList, hotelsInBounds, visibleHotels]
  );
  // get only the top filters count while maintaining the order of filtersList
  const suggestedFilters = useMemo(
    () => calculateTopSuggestedFilters(suggestedFilterCounts, amenities, maxSuggestedFilters),

    [suggestedFilterCounts, amenities, maxSuggestedFilters]
  );

  const topSuggestedFilters = suggestedSaleFilter
    ? {
        ...suggestedSaleFilter,
        ...suggestedFilters,
      }
    : suggestedFilters;

  return { topSuggestedFilters, isAtleastOneSuggestedFilter };
};
