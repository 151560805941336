import { COMPARE_PROPERTIES } from '../../../constants';
import { useGetMVTSelections } from '../../../hooks';

//NHCSEARCH-5249  MVT Compare Hotels

export const useGetCompareHotelMVTVariants = () => {
  const { isLoaded: isCompareHotelsTestLoaded, isMVTVariant: CompareHotelsVariant } =
    useGetMVTSelections({
      agentId: COMPARE_PROPERTIES.agentId,
      MVTVariants: ['a', 'b', 'c'],
    });

  const isCompareHotelsCheckboxVariant = isCompareHotelsTestLoaded && !!CompareHotelsVariant?.b;
  const isCompareHotelsToggleVariant = isCompareHotelsTestLoaded && !!CompareHotelsVariant?.c;

  return { isCompareHotelsCheckboxVariant, isCompareHotelsToggleVariant };
};
